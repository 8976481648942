@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?3a5s9r');
  src:  url('../fonts/icomoon.eot?3a5s9r#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?3a5s9r') format('truetype'),
    url('../fonts/icomoon.woff?3a5s9r') format('woff'),
    url('../fonts/icomoon.svg?3a5s9r#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-check:before {
  content: "\e93b";
}
.icon-box-lunch:before {
  content: "\e92e";
}
.icon-surfer:before {
  content: "\e92c";
}
.icon-shopping:before {
  content: "\e915";
}
.icon-blanket-factory:before {
  content: "\e92b";
}
.icon-sunset-cruise:before {
  content: "\e912";
}
.icon-restaurant:before {
  content: "\e917";
}
.icon-panoramic-views:before {
  content: "\e918";
}
.icon-lunch-time:before {
  content: "\e919";
}
.icon-church:before {
  content: "\e91c";
}
.icon-camera:before {
  content: "\e91d";
}
.icon-cactus:before {
  content: "\e91e";
}
.icon-snorkel:before {
  content: "\e921";
}
.icon-bag:before {
  content: "\e926";
}
.icon-clock:before {
  content: "\e90f";
}
.icon-beach-time:before {
  content: "\e90c";
}
.icon-shopping-time:before {
  content: "\e901";
}
