@font-face {
	font-family: 'HighestPraise-Regular';
	src: url('../fonts/HighestPraise-Regular.otf');
	font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: 'Northwell';
	src: url('../fonts/Northwell.otf');
	font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: 'Northwell-Alt';
	src: url('../fonts/Northwell-Alt.otf');
	font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: 'Northwell-Swash';
	src: url('../fonts/Northwell-Swash.otf');
	font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: 'Reef';
	src: url('../fonts/Reef.otf');
	font-weight: normal;
  font-style: normal;
  font-display: swap;
}