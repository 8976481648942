* {
  box-sizing: border-box;
}

.banner-home-hero {
  min-height: 700px;
  width: 100%;
  background-image: url('/resources/img/home-hero-resp.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-home-hero img {
  width: 70%;
}

.centered-in-banner {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.no-logo-title {
  text-align: center;
  font-size: 6em;
  color: #FFF;
  font-family: 'Northwell';
}

.index-tours {
  background-color: rgb(42, 98, 202);
  min-height: 700px;
  width: 100%;
}

.index-tours-table {
  display: block;
  height: 100%;
}

.index-tours-table > div:first-child {
  flex: inherit;
}

.index-tours-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #FFF;
  padding-left: 20px;
}

.index-tours-container > div:nth-child(1) {
  flex: 1;
}

.index-tours-container > div:nth-child(2) {
  flex: 8;
  margin-bottom: 20px;
}

.index-tours-container > div:nth-child(3) {
  flex: 1;
}

.index-title {
  padding-top: 15px;
  font-family: 'Northwell';
  font-size: 3em;
  display: flex;
  align-items: center;
}

ul {
  padding: 0 0;
  margin: 0;
  text-decoration: none;
}

li {
  text-decoration: none;
  list-style-type: none;
}

.index-tours-container ul {
  padding: 0 0;
  margin: 0;
  text-decoration: none;
}

.index-tours-container ul li {
  text-decoration: none;
  list-style-type: none;
  text-align: left;
}

.index-tours-container ul li a div {
  border: 2px solid #FFF;
  border-radius: 20px;
  margin: 5px 0;
  padding: 10px 5px;
  display: inline-block;
}

.index-tours-container ul li a {
  text-decoration: none;
  color: inherit;
}

.index-tours-container ul li a div img {
  width: 20px;
  display: inline-block;
  margin-left: 15px;
  vertical-align: middle;
}

.index-tours-footer {
  display: flex;
  justify-content: space-between;
}

.index-tours-footer-icons {
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.index-tours-footer img {
  width: 40px;
}

.index-tours-footer-arrow-right {
  margin-right: 40px;
}

.index-tours-footer-arrow-right img {
  width: 30px;
}

.tour-detail {
  min-height: 700px;
  width: 100%;
  overflow: hidden;
}

.tour-detail-container {
  width: 100%;
  height: 100%;
  position: relative;
  /* top: -200px;
  left: -200px; */
}

.youtube-background {
  width: 100%;
  height: 100%;
}

.tour-detail-banner {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 56.3vw;
  top: 0;
  left: 0;
  width: 100%;
  text-align: left;
  z-index: 0;
}

.tour-detail-banner > div {
  display: inline-block;
}

.tour-detail-width-container {
  position: absolute;
  left: 5px;
  top: 5px;
}

.tour-detail-name {
  display: inline-block;
  color: #FFF;
  font-size: 1.8em;
  font-family: 'Northwell';
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 5px;
  display: flex;
  align-items: center;
  min-height: 80px;
}

.tour-detail-duration {
  background-color: rgb(42, 98, 202);
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 45px;
  color: #FFF;
  display: flex;
  float: right;
  justify-content: center;
  align-items: center;
  font-size: .9em;
}

.opacity-information {
  opacity: .9;
}

.tour-detail-information::before {
  display: block;
  content: "";
  width: 100%;
  height: 30px;
  background: transparent url('/resources/img/brochazo-blanco.png') no-repeat;
  position: absolute;
  top: -29px;
  left: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.tour-detail-information {
  min-height: 40%;
  padding: 10px 15px;
  text-align: center;
  bottom: 0;
  width: 100%;
  background-color: #FFF;
  position: relative;
}

.tour-detail-information > div:first-child {
  display: block;
  justify-content: space-between;
  position: relative;
}

.tour-detail-title {
  width: 100%;
  display: inline-block;
  margin: 10px 0;
}

.tour-detail-price {
  display: inline-block;
  background-image: url('/resources/img/brochazo-promojulio.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 200px;
  height: 40px;
  color: #FFF;
  text-align: center;
  font-size: 1.2em;
  padding-top: 5px;
  z-index: 3;
  position: absolute;
  right: 0;
  top: -30px;
}

.tour-detail-title-compound {
  color: #ff9a2a;
  text-align: left;
}

.tour-detail-title-compound > div:first-child {
  font-size: 1.6em;
  margin-right: 30px;
}

.tour-detail-title-compound > div:last-child {
  font-size: 1em;
  margin-top: 15px;
}

.background-olita {
  background-image: url('/resources/img/olita-larga.png');
  height: 20px;
  width: 100%;
  background-repeat: no-repeat;
}

.activities-pickups {
  margin-top: 10px;  
}

.activities {
  margin: 15px 0;
  display: inline-block;
}

.activities ul {
  margin: 0 15px;
  display: block;
}

.activities ul li {
  padding: 8px 0;
  display: flex;
  text-align: left;
}

.activities ul li span {
  margin-right: 10px;
}

.pickups table thead tr td:first-child {
  color: rgb(42, 98, 202);
}

.pickups > div:first-child {
  width: 100%;
}

.pickups > div {
  margin-bottom: 15px;
  display: inline-block;
}

.pickups table {
  color: #ff9a2a;
  text-align: left;
}

.season {
  font-size: 1.4em;
  color: #ff9a2a !important;
  font-weight: bold;
}

.pickups table tr td {
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 5px;
  vertical-align: top;
}

button {
  border: none;
  background: transparent;
}

.buttons a {
  padding: 20px 0;
  text-align: center;
  margin: 5px;
}

.buttons img {
  width: 50px;
}

.tour-detail-width-10 {
  min-width: 10%;
}

.tour-detail-width-20 {
  min-width: 20%;
}

.tour-detail-width-25 {
  min-width: 25%;
}

.tour-detail-width-30 {
  min-width: 30%;
}

.tour-detail-width-40 {
  min-width: 40%;
}

.tour-detail-width-50 {
  min-width: 50%;
}

.logos {
  position: absolute;
  bottom: 20px;
  right: 50px;
}

.logos > div{
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}

.logos .logo-certs {
  width: 100px;
}

.loading-fixed {
  position: fixed;
}

.loading-absolute {
  position: absolute;
}

.loading-container {
	z-index: 101;
	width: 40px;
	height: 40px;
	left: 0;
  top: -40%;
  bottom: 0;
  right: 0;
  margin: auto;
	opacity: 1;
	visibility: visible;
	transition: opacity 0.5s linear, visibility 0.5s;
}

.pulse {
	border: 3px solid rgb(42, 98, 202);
	height: 40px;
	width: 40px;
	position: absolute;
	left: 48%;
	top: 50%;
	animation: pulsate 1s ease-out infinite;
	border-radius: 40px;
	opacity: 0;
}

@keyframes pulsate {
	0% {
		transform: scale(0.1, .1);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: scale(1.2, 1.2);
		opacity: 0;
	}
}

@-webkit-keyframes pulsate {
	0% {
		-webkit-transform: scale(0.1, .1);
		opacity: 0;
	}
	50% {
		opacity: 1
	}
	100% {
		-webkit-transform: scale(1.2, 1.2);
		opacity: 0
	}
}

@-moz-keyframes pulsate {
	0% {
		transform: scale(0.1, .1);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: scale(1.2, 1.2);
		opacity: 0;
	}
}

@-o-keyframes pulsate {
	0% {
		transform: scale(0.1, .1);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: scale(1.2, 1.2);
		opacity: 0;
	}
}