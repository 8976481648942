@media screen and (min-width: 800px) {
  .banner-home-hero {
   background-image: url('/resources/img/home-hero.jpg');
   height: 100vh;
  }

  .index-tours {
    min-height: 100vh;
  }

  .index-tours-table {
    display: flex;
  }
  
  .index-tours-table > div:first-child {
    flex: 2;
  }
  
  .index-tours-table > div:last-child {
    flex: 1;
  }

  .tour-detail-title {
    margin: 0;
    width: auto;
  }
  
  .tour-detail-title-compound {
    display: flex;
    align-items: center;
  }

  .pickups > div {
    padding-left: 15px;
  }

  .pickups table tr td {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  .tour-detail {
    height: 100vh;
  }

  .tour-detail-banner {
    height: 60vh;
    position: absolute;
  }

  .tour-detail-width-container {
    left: 15px;
    top: 15px;
  }

  .tour-detail-information {
    padding: 15px 0 0 30px;
    text-align: left;
    position: absolute;
  }

  .index-tours-thumbnail {
    height: 100%;
    width: 100%;
    background-image: url('/resources/img/indice-hero.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }

  .activities-pickups {
    display: flex;
    justify-content: space-around;
  }

  .activities {
    display: flex;
    margin: 0;
  }

  .tour-detail-title-compound > div:last-child {
    margin-top: 0;
  }

  .buttons {
    display: flex;
    align-items: flex-end;
    padding: 0;
  }

  .buttons img {
    width: 30px;
  }

  .tour-detail-banner > div {
    /* padding: 20px 0 0 20px; */
  }

  .tour-detail-name {
    min-width: 280px;
    font-size: 3em;
    padding-top: 20px;
    margin-left: 20px;
  }

  .tour-detail-banner::after {
    bottom: -5px;
  }
  
  .tour-detail-duration {
    width: 60px;
    height: 60px;
    border-radius: 60px;
  }

  .tour-detail-information > div:first-child {
    display: flex;
    justify-content: space-between;
  }

  .tour-detail-price {
    margin-top: -40px;
    margin-right: 40px;
    position: relative;
    top: inherit;
  }
 }